import evenementsService from '@/services/http/evenements.service';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Actually a search
    async fetchEvents({ rootState }, data) {
      const res = await evenementsService.fetchEvents(data);
      return res;
    },

    async addEvent({ rootState }, data) {
      const res = await evenementsService.addEvent(data);
      return res;
    },

    async getEvent({ rootState }, data) {
      const res = await evenementsService.getEvent(data);
      return res;
    },

    async getAllEvents({ rootState }, data) {
      const res = await evenementsService.getAllEvents(data);
      return res;
    },

    async updateEvent({ rootState }, data) {
      const res = await evenementsService.updateEvent(data);
      return res;
    },
  },
};
