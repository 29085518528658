<template>
  <div class="container-fluid d-flex flex-column">
    <div class="d-flex justify-content-end">
      <b-button
        class="mb-1 float-right"
        variant="outline-primary"
        @click="
          () => {
            $router.push({ name: 'dashboard' })
          }
        "
      >
        <i class="las la-arrow-left mr-25" />
        <span>{{ $t('button.return') }}</span>
      </b-button>
    </div>
    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :events="events"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-add-event"
      ref="modal-add-event"
      content-class="shadow"
      title="Ajouter un rendez-vous."
      size="lg"
      centered
      hide-footer
    >
      <div>
        <b-spinner v-if="isFetchingDemande == true" small />

        <div v-else class="demandes">
          <h3>{{ $t('demand_detail.associated') }}</h3>
          <div>
            <h6>
              <strong>{{ $t('demand_detail.reference') }}</strong>
              {{ demande.reference === null ? '' : demande.reference }}
            </h6>
            <h6>
              <strong>{{ $t('demand_detail.esta') }}</strong
              ><span>{{
                demande.data.raison_sociale == null
                  ? ''
                  : demande.data.raison_sociale
              }}</span>
            </h6>
            <h6>
              <strong>{{ $t('demand_detail.pro') }}</strong>
              {{
                demande.data.nom_promoteur === null
                  ? ''
                  : demande.data.nom_promoteur
              }}
              {{
                demande.data.prenom_promoteur === null
                  ? ''
                  : demande.data.prenom_promoteur
              }}
            </h6>
            <h6>
              <strong>{{ $t('demand_detail.type_request') }}</strong>
              {{
                $t(
                  getTypeDemandebyCode(
                    demande.data.code_demande === null
                      ? 0
                      : demande.data.code_demande
                  )
                )
              }}
            </h6>
            <h6>
              <strong>{{ $t('demand_detail.date') }}</strong>
              {{ demande.created_at === null ? '' : demande.created_at }}
            </h6>
          </div>
        </div>

        <hr />
        <validation-observer ref="formRulesAdd">
          <div class="col-lg">
            <b-form-group
              label="Type de rendez-vous"
              label-cols-md="12"
              disabled
            >
              <v-select
                v-model="newEvent.type_id"
                disabled
                label="label"
                :reduce="(option) => option.optn"
                :options="typeEvents"
              />
            </b-form-group>

            <b-form-group>
              <div class="row">
                <span class="col-12">{{
                  $t('demand_detail.appointment')
                }}</span>
                <span class="col-12">
                  <b-form-group>
                    <b-form-input
                      v-model="newEvent.title"
                      :placeholder="$t('demand_detail.appointment')"
                      disabled
                    />
                  </b-form-group>
                </span>
              </div>
            </b-form-group>

            <b-form-group>
              <div class="row">
                <span class="col-12">{{ $t('demand_detail.rdv_date') }}</span>
                <span class="col-12">
                  <b-form-group>
                    <flat-pickr
                      v-model="event_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d' }"
                      @input="get_new_date()"
                    />
                  </b-form-group>
                </span>
              </div>
            </b-form-group>
            <b-form-group
              :label="$t('demand_detail.interval')"
              label-cols-md="12"
            >
              <div class="d-flex justify-content-between">
                <b-form-radio
                  v-for="(interval, index) in hourIntervals"
                  :key="index"
                  v-model="selected_interval"
                  name="some-radios"
                  :value="interval"
                  @change="getHours(interval)"
                >
                  {{ interval }}
                </b-form-radio>
              </div>
            </b-form-group>
            <b-form-group
              :label="$t('demand_detail.rdv_hour')"
              label-cols-md="12"
            >
              <v-select
                v-model="selected_hour"
                label="value"
                :options="hours"
                :reduce="(option) => option"
                @input="get_new_date()"
              />
            </b-form-group>
            <!-- <b-form-group>
              <div class="row">
                <span class="col-12">Date début</span>
                <span class="col-12">
                  <b-form-group>
                    <flat-pickr
                      v-model="newEvent.start"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d' }"
                    />
                  </b-form-group>
                </span>
              </div>
            </b-form-group>
            <b-form-group>
              <div class="row">
                <span class="col-12">Date fin</span>
                <span class="col-12">
                  <b-form-group>
                    <flat-pickr
                      v-model="newEvent.end"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                    />
                  </b-form-group>
                </span>
              </div>
            </b-form-group> -->
            <b-form-group>
              <div class="row">
                <span class="col-12">{{
                  $t('demand_detail.register_rdv')
                }}</span>
                <span class="col-12">
                  <b-form-textarea
                    v-model="newEvent.description"
                    rows="2"
                    class="form-input"
                    :placeholder="$t('demand_detail.description')"
                  />
                </span>
              </div>
            </b-form-group>
          </div>
        </validation-observer>
        <hr />
        <div class="d-flex flex-row-reverse">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="isupdate == true ? update_rdv() : save_rdv()"
          >
            <b-spinner v-if="isCreatingEvent == true" small />
            {{ $t('demand_detail.register_rdv') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BCollapse,
  BCard,
  BCardText,
  BFormTextarea,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  VBToggle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BPagination,
  BFormCheckbox,
  VBTooltip,
  BSpinner,
  BModal,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import FullCalendar from '@fullcalendar/vue'

import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import { ContentLoader } from 'vue-content-loader'
import VueHtml2pdf from 'vue-html2pdf'
// import html2pdf from "html2pdf.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppLogo from '@/components/AppLogo.vue'
import utilsService from '@/services/utils/utils.service'
import formulaireStoreModule from '@/store/formulaire'
import evaluationsStoreModule from '@/store/evaluations'
import evenementsStoreModule from '@/store/evenements'
import usersStoreModule from '@/store/users'
import demandesStoreModule from '@/store/demandes'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import flatPickr from 'vue-flatpickr-component'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'
import localstorageService from '@/services/localstorage/localstorage.service'

// import FullCalendar from "@fullcalendar/vue";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import interactionPlugin from "@fullcalendar/interaction";

// import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
// import "../../../../node_modules/vue-simple-calendar/dist/style.css";
// The next two lines are optional themes
// import "../../../../node_modules/vue-simple-calendar/dist/css/default.css";
// import "../../../../node_modules/vue-simple-calendar/dist/css/holidays-us.css";

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import InvoiceSidebarAddPayment from '../invoice/InvoiceSidebarAddPayment.vue'
import InvoiceSidebarSendInvoice from '../invoice/InvoiceSidebarSendInvoice.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormCheckbox,
    BCard,
    BCardText,
    BCardHeader,
    BCardBody,
    BRow,
    BForm,
    BFormInput,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCollapse,
    BDropdownDivider,
    BSpinner,
    BModal,
    BFormTextarea,
    BFormRadio,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    vSelect,
    AppTimeline,
    AppTimelineItem,
    flatPickr,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,

    FullCalendar,
    // CalendarView,
    // CalendarViewHeader,
    AppLogo,

    ContentLoader,
    VueHtml2pdf,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'users', module: usersStoreModule },
      { path: 'formulaire', module: formulaireStoreModule },
      { path: 'evaluations', module: evaluationsStoreModule },
      { path: 'evenements', module: evenementsStoreModule },
      { path: 'demandes', module: demandesStoreModule },
    ]

    // Register module

    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  data() {
    const { currentUserId } = utilsService.currentUserUtils()
    return {
      showDate: new Date(),
      isCreatingEvent: false,
      event: null,
      event_date: '',
      newEvent: {
        name: null,
        start: null,
        end: null,
        description: null,
        type_id: null,
        user_id: null,
      },
      selected_interval: '1h',
      selected_hour: {
        start: null,
        end: null,
        value: null,
      },
      hourIntervals: ['1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h'],
      typeEvents: [
        {
          label: this.$t('demand_detail.event1'),
          optn: 'technical',
        },
        {
          label: this.$t('demand_detail.event2'),
          optn: 'control',
        },
        {
          label: this.$t('demand_detail.event3'),
          optn: 'classment',
        },
        {
          label: this.$t('demand_detail.event4'),
          optn: 'audit',
        },
        {
          label: this.$t('demand_detail.event5'),
          optn: 'applicant_submit_document',
        },
      ],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        timeZone: 'local',
        initialDate: new Date(),
        locale: frLocale,

        headerToolbar: {
          start: 'prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        eventClick: this.handleEventClick,
        dateClick: this.handleDateClick,
        validRange: this.validRange,
        //  eventContent: { default: this.renderEventContent },
        editable: true,
        eventResizableFromStart: true,
        dragScroll: true,
        navLinks: true,
      },

      treatment_agent_id: '',
      date_rendez_vous_audit: '',
      auditor_id: '',
      date_rendez_vous_technical_visit: '',
      technical_visitor_id: '',

      can_treate: null,
      current_id: null,
      checklist_object_for_print: null,
      options_mtca_agents: [],
      options_mtca_admins: [],
      curr_decisions: [],

      check_object: null,
      checklist_object: null,
      pdf: null,
      canDownload: false,
      current_demand_id: '',

      selectedForm: null,
      subject: '',
      fileName: '',
      hours: [],

      currentUserId,
      states: null,
      current_state: null,
      next_state: null,
      should_have_auto_eval: null,
      request_have_auto_eval: null,
      request_have_audit: null,
      request_technical_visit: null,
      can_update: null,
      evaluationResult: null,
      auditResult: null,
      forPrint: false,
      signature: null,
      isDownloading: false,
      file_for_vizualisation: null,
      show_vizualisation_section: false,
      can_show: false,
      isupdate: false,
      rdv_params: localstorageService.getRdvParams(),
      events: [],
      isFetchingDemande: false,
      demande: {
        code_demande: '',
      },
      name_test: '',
    }
  },
  computed: {
    getHoursOptions() {
      return this.hours.map((hour) => ({
        text: `${hour.start} - ${hour.end}`,
        value: hour, // Keep the entire object for access
      }))
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        const { user_id } = this.$route.params
        // console.log('rdv_params::: ', this.rdv_params)
      },
    },
    can_show: {
      handler(val, old) {
        // console.log('can_show::: ', val)
      },
    },
  },
  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },
  async created() {
    await this.fetchEvents()
    //  await this.getEv()
  },
  mounted() {
    this.calendarOptions = {
      ...this.calendarOptions,
      events: this.events,
    }
    this.newEvent = {
      ...this.newEvent,
      type_id: this.rdv_params.meet_type,
    }
    // console.log('newEvent', this.newEvent)
    this.get_demande()
    this.getHours(this.selected_interval)
  },
  computed: {
    ...mapGetters('demandes', {
      get_rdv_params: 'getRdvParams',
    }),
  },
  methods: {
    ...mapActions('users', {
      action_searchUsers: 'searchUsers',
    }),
    ...mapActions('formulaire', {
      action_getFormulaire: 'getFormulaire',
      action_updateFormulaire: 'updateFormulaire',
      action_updateFormulaireFormData: 'updateFormulaireFormData',
      action_downloadBilan: 'downloadBilan',
      action_downloadQa: 'downloadQaEvaluation',
      action_downloadResult: 'downloadEvaluationResult',
    }),
    ...mapActions('evaluations', {
      action_downloadQa: 'downloadQaEvaluation',
      action_downloadResult: 'downloadEvaluationResult',
      action_getEvaluation: 'getEvaluation',
    }),
    ...mapActions('evenements', {
      action_fetchEvents: 'fetchEvents',
      action_addEvent: 'addEvent',
      action_updateEvent: 'updateEvent',
      action_getEvent: 'getEvent',
    }),
    ...mapActions('demandes', {
      action_getDemande: 'getDemmande',
    }),
    // -----------------------------------------------------------
    showModal() {
      this.$refs['modal-add-event'].show()
    },
    hideModal() {
      this.$refs['modal-add-event'].hide()
    },

    handleEventClick(info) {
      this.newEvent = {}
      this.selected_hour = null
      this.selected_interval = '1h'
      this.event_date = this.formatDatetime(info.event.startStr).date
      this.selected_hour = {
        start: this.formatDatetime(info.event.startStr).time,
        end: this.formatDatetime(info.event.endStr).time,
        value: `${this.formatDatetime(info.event.startStr).time} - ${
          this.formatDatetime(info.event.endStr).time
        }`,
      }
      this.selected_interval = `${
        this.formatDatetime(info.event.endStr).hour -
        this.formatDatetime(info.event.startStr).hour
      }h`

      // console.log('Event clicked:', info.event, this.selected_interval)
      this.newEvent = {
        ...this.newEvent,
        id: info.event.id,
        title: info.event.title,
        start: this.formatDatetime(info.event.startStr).date,
        end: this.formatDatetime(info.event.endStr).date,
        description: info.event.extendedProps.description,
        type_id: info.event.extendedProps.type_id,
      }
      // console.log('Event clicked:', this.newEvent)
      this.isupdate = true
      this.showModal()
      this.get_new_date()
    },
    formatDatetime(dates) {
      const date = new Date(dates)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const fullAttrrib = {
        date: `${year}-${month}-${day}`,
        time: `${hours}:${minutes}`,
        datetime: `${year}-${month}-${day} ${hours}:${minutes}`,
        hour: `${hours}`,
      }
      // If you only want to display date, remove the time portion below
      return fullAttrrib

      // For just time, uncomment and use this line instead
      // return `${hours}:${minutes}`;
    },

    fetchEvents() {
      this.events = []
      const reqOptions = {
        user_id: this.rdv_params.user_id,
        per_page: 100,
        page: 1,
      }
      this.action_fetchEvents(reqOptions)
        .then(async (response) => {
          if (response.status === 200) {
            response.data.data.map((event) => {
              console.log('event_type', this.events)
              let evt

              if (this.rdv_params.meet_type == 'applicant_submit_document') {
                evt = {
                  id: event.id,
                  title: event.titre,
                  start: this.convertDateFormat(event.start_datetime),
                  end: this.convertDateFormat(event.end_datetime),
                  description: event.description,
                  type_id: event.type,
                  demande_id: null,
                }
              } else {
                evt = {
                  id: event.id,
                  title: event.titre,
                  start: this.convertDateFormat(event.start_datetime),
                  end: this.convertDateFormat(event.end_datetime),
                  description: event.description,
                  type_id: event.type,
                  demande_id: event.evenementable.demande_id,
                }
              }

              this.events.push(evt)
              console.log('event_type', this.events)
            })
            // console.log('events:::🔘🔘🔘🔘🔘🔘🔘 ', this.events)
            this.calendarOptions = {
              ...this.calendarOptions,
              events: this.events,
            }
          }
        })
        .catch((err) => {
          // console.log('err::: ', err)
        })
    },
    handleDateClick(info) {
      this.newEvent = {}
      this.selected_hour = null
      this.selected_interval = '1h'
      // console.log('date clicked:', info)
      this.event_date = info.dateStr
      this.newEvent = {
        ...this.newEvent,
        title: this.name_test,
        type_id: this.rdv_params.meet_type,
        start: info.dateStr,
      }
      this.isupdate = false
      this.showModal()
    },
    renderEventContent(info) {
      // console.log('info.event.extendedProps.type_id::: ', info)
      return {
        html: `
      <span>${info.event.title}</span><br />
      <span>Extended Info: ${info.event.extendedProps.description}, ${info.event.extendedProps.type_id}</span>
    `,
      }
    },
    validRange() {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(today.getDate() + 1)
      const tomorrowString = tomorrow.toISOString().split('T')[0]
      return {
        start: tomorrowString,
      }
    },
    get_new_date() {
      this.newEvent = {
        ...this.newEvent,
        start: `${this.event_date} ${this.selected_hour.start}`,
        end: `${this.event_date} ${this.selected_hour.end}`,
      }

      // console.log('urfn', JSON.stringify(this.newEvent))
    },
    async get_demande() {
      this.isFetchingDemande = true
      //  // console.log('id::: ', this.$route.params)
      const id = this.rdv_params.request_id
      // console.log('id::: ', id)
      await this.action_getDemande(id)
        .then(async (response) => {
          if (response.status === 200) {
            // console.log(response.data.data)
            this.demande = response.data

            if (this.rdv_params.meet_type == 'classment') {
              this.name_test = `Contrôle de classement de ${this.demande.data.raison_sociale}`
            }
            if (this.rdv_params.meet_type == 'technical') {
              this.name_test = `Contrôle technique de ${this.demande.data.raison_sociale}`
            }
            if (this.rdv_params.meet_type == 'audit') {
              this.name_test = `Audit de ${this.demande.data.raison_sociale}`
            }
            if (this.rdv_params.meet_type == 'applicant_submit_document') {
              this.name_test = `Retrait du document final par ${this.demande.data.raison_sociale}`
            }
            // console.log('name_test::: ', this.name_test)
          }
          this.isFetchingDemande = false
        })
        .catch((err) => {
          this.isFetchingDemande = false
          // console.log('err::: ', err)
        })
    },
    getHours(interval) {
      this.hours = []
      const startTime = 8
      const endTime = 22
      // const plages = [];
      let plageStart = startTime
      while (plageStart + parseInt(interval.slice(0, -1)) <= endTime) {
        const plageEnd = Math.min(
          endTime,
          plageStart + parseInt(interval.slice(0, -1))
        )
        this.hours.push({
          start: `${plageStart}:00`,
          end: `${plageEnd}:00`,
          value: `${plageStart}:00 - ${plageEnd}:00`,
        })
        plageStart = plageEnd
      }
      // console.log(JSON.stringify(this.hours))
    },
    getTypeDemandebyCode(code) {
      const selectedForm = utilsService.getFormulaireByCodeDemande(code)
      return selectedForm.title
    },
    async save_rdv() {
      // console.log('canAddOrUpdateEvent::: ', this.newEvent)
      this.isCreatingEvent = true
      const canAddOrUpdateEvent = await this.canAddOrUpdateEvent(
        this.newEvent,
        'create',
        this.events
      )
      if (canAddOrUpdateEvent.value == false) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: canAddOrUpdateEvent.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.hideModal()
      } else {
        let data
        //  this.events.push(this.newEvent)
        if (this.rdv_params.meet_type == 'applicant_submit_document') {
          data = {
            type: this.newEvent.type_id,
            user_id: this.currentUserId,
            start_datetime: this.newEvent.start,
            end_datetime: this.newEvent.end,
            titre: this.newEvent.title,
            description: this.newEvent.description,
            demande_id: this.rdv_params.request_id,
          }
        } else {
          data = {
            type: this.newEvent.type_id,
            user_id: this.currentUserId,
            start_datetime: this.newEvent.start,
            end_datetime: this.newEvent.end,
            titre: this.newEvent.title,
            description: this.newEvent.description,
            visite_id: this.rdv_params.visit_id,
          }
        }

        // console.log('data::: ', data)
        await this.action_addEvent(data)
          .then((response) => {
            if (response.status == 201) {
              this.isCreatingEvent = false

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('demand_detail.rdv_success'),
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: '',
                },
              })
              this.fetchEvents()
            }
          })
          .catch((error) => {
            this.isCreatingEvent = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('demand_detail.error'),
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: '',
              },
            })
          })

        this.hideModal()
        this.newEvent = {}
      }
      //  // console.log('canAddOrUpdateEvent::: ', canAddOrUpdateEvent)
    },
    convertDateFormat(dateString) {
      try {
        // Split the date and time components
        const [date, time] = dateString.split(' ')

        // Split the date further into day, month, and year, handling potential edge cases
        const [day, month, yearString] = date.split('-')
        const year = parseInt(yearString, 10) // Ensure valid integer year

        // Validate date components (optional, based on your requirements)
        if (isNaN(year) || month < 1 || month > 12 || day < 1 || day > 31) {
          throw new Error('Invalid date format or components')
        }

        // Reassemble the date in the desired format
        const convertedDate = `${year}-${month
          .toString()
          .padStart(2, '0')}-${day.toString().padStart(2, '0')}`

        // Combine the converted date and time
        return `${convertedDate} ${time}`
      } catch (error) {
        console.error('Error converting date format:', error)
        return null // Or provide a default value or handle the error differently
      }
    },
    getEv() {
      this.events = [
        {
          id: 1,
          title: 'Event test',
          start: this.convertDateFormat('14-03-2024 20:00:00'),
          end: this.convertDateFormat('14-03-2024 21:00:00'),
          description: 'Description for Event 1',
          type_id: 1,
        },
      ]
      //  console.log(
      //   "events::: (●''●)(●''●)(●''●)(●''●)",
      //   this.events,
      //   this.calendarOptions
      // )
    },
    async update_rdv() {
      // console.log(this.newEvent)
      this.isCreatingEvent = true
      const canAddOrUpdateEvent = await this.canAddOrUpdateEvent(
        this.newEvent,
        'update',
        this.events
      )
      if (canAddOrUpdateEvent.value == false) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: canAddOrUpdateEvent.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.hideModal()
      } else {
        //  this.events.push(this.newEvent)
        const data = {
          type: this.newEvent.type_id,
          user_id: this.currentUserId,
          start_datetime: this.newEvent.start,
          end_datetime: this.newEvent.end,
          titre: this.newEvent.title,
          description: this.newEvent.description,
          demande_id: parseInt(this.rdv_params.request_id),
        }
        // console.log('data::: ', data)
        await this.action_updateEvent({ data, id: this.newEvent.id })
          .then((response) => {
            if (response.status == 202) {
              this.isCreatingEvent = false

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('demand_detail.update'),
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: '',
                },
              })
              this.fetchEvents()
            }
          })
          .catch((error) => {
            this.isCreatingEvent = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('demand_detail.error'),
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: '',
              },
            })
          })
        this.hideModal()
        this.newEvent = {}
      }
      //   // console.log('canAddOrUpdateEvent::: ', canAddOrUpdateEvent)
    },

    canAddOrUpdateEvent(event, typeOperation, listOfUserEvents) {
      // console.log(
      //   'event::: ',
      //   event,
      //   typeOperation === 'update',
      //   listOfUserEvents
      // )
      this.events.map((evt) => {
        // console.log('event::: ', evt)
      })
      let eventfind
      // Check if the event date is on a weekend
      const isWeekend =
        new Date(Date.parse(event.start)).getDay() === 0 ||
        new Date(Date.parse(event.start)).getDay() === 6

      // Handle create operation
      if (typeOperation === 'create') {
        // Check for existing events on the same date
        const existingEvent = listOfUserEvents.find((existing) => {
          const existingStart = new Date(Date.parse(existing.start))
          const existingEnd = new Date(Date.parse(existing.end))
          const eventStart = new Date(Date.parse(event.start))
          const eventEnd = new Date(Date.parse(event.end))
          eventfind = existing
          return (
            (existingStart <= eventStart && eventEnd <= existingEnd) ||
            (eventStart <= existingStart && existingEnd <= eventEnd)
          )
        })
        if (isWeekend) {
          // // console.log(
          //   'You cannot create an event on this date as it is a weekend.'
          // )
          this.isCreatingEvent = false
          const existingEventDate = {
            message: this.$t('demand_detail.message1'),
            value: false,
          }
          return existingEventDate
        }

        /// verify if controle exist
        if (this.events.length !== 0) {
          let existingEventDate = ''
          this.events.forEach((e) => {
            console.log('lenght-----', this.rdv_params.meet_type, e.type_id)
            if (
              e.demande_id === parseInt(this.rdv_params.request_id, 10) &&
              e.type_id === 'technical' &&
              this.rdv_params.meet_type === 'technical'
            ) {
              this.isCreatingEvent = false
              existingEventDate = {
                message: this.$t('demand_detail.message2'),
                value: false,
              }
            }
            if (
              e.demande_id === parseInt(this.rdv_params.request_id, 10) &&
              e.type_id === 'classment' &&
              this.rdv_params.meet_type === 'classment'
            ) {
              this.isCreatingEvent = false
              existingEventDate = {
                message: this.$t('demand_detail.message3'),
                value: false,
              }
            }
            if (
              e.demande_id === parseInt(this.rdv_params.request_id, 10) &&
              e.type_id === 'audit' &&
              this.rdv_params.meet_type === 'audit'
            ) {
              this.isCreatingEvent = false
              existingEventDate = {
                message: this.$t('demand_detail.message4'),
                value: false,
              }
            }
          })
          // console.log('lenght-----', existingEventDate)
          return existingEventDate
        }
        // -------------------------------------------

        if (existingEvent) {
          this.isCreatingEvent = false
          const existingEventDate = {
            message: this.$t('demand_detail.message5'),
            value: false,
          }
          return existingEventDate
        }
        const existingEventDate = {
          message: this.$t('demand_detail.message6'),

          value: true,
        }
        return existingEventDate
      }

      // Handle update operation (assuming update is allowed even if there are existing events)
      if (typeOperation === 'update') {
        const existingEvent = listOfUserEvents.find((existing) => {
          const existingStart = new Date(Date.parse(existing.start))
          const existingEnd = new Date(Date.parse(existing.end))
          const eventStart = new Date(Date.parse(event.start))
          const eventEnd = new Date(Date.parse(event.end))
          eventfind = existing
          return (
            (existingStart <= eventStart && eventEnd <= existingEnd) ||
            (eventStart <= existingStart && existingEnd <= eventEnd)
          )
        })
        if (isWeekend) {
          this.isCreatingEvent = false
          const existingEventDate = {
            message: this.$t('demand_detail.message7'),
            value: false,
          }
          return existingEventDate
        }
        if (existingEvent) {
          if (event.id == eventfind.id) {
            // console.log('id == ')
            const existingEventDate = {
              message: this.$t('demand_detail.message8'),

              value: true,
            }
            return existingEventDate
          }
          this.isCreatingEvent = false
          const existingEventDate = {
            message: this.$t('demand_detail.message9'),

            value: false,
          }
          return existingEventDate
        }
        const existingEventDate = {
          message: this.$t('demand_detail.message10'),
          value: true,
        }
        return existingEventDate
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/pages/app-invoice.scss';
@import '@core/scss/vue/apps/calendar.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';

.pdf-item {
  page-break-inside: avoid;
}
.demandes {
  border: 1px solid #286d64;
  border-radius: 10px;
  // box-shadow: 1px 1px 3px #dee2e6;
  padding: 10px 15px;
  cursor: pointer;
}
</style>
