import { BaseService } from "./base.service";

export class EvenementsService extends BaseService {
  constructor() {
    super();
  }

  fetchEvents(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/evenements/search", data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  addEvent(post) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/evenements", post)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getAllEvents() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get("/evenements")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getEvent(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/evenements/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateEvent(object) {
    const { id, data } = object;
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/evenements/${id}`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

export default new EvenementsService();
